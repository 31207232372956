export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDAk6KQnLWrfxQSzkJfiLhoCH2Dldupdo8',
    authDomain: 'meditatorsgroup-dev.firebaseapp.com',
    databaseURL: 'https://meditatorsgroup-dev.firebaseio.com',
    projectId: 'meditatorsgroup-dev',
    storageBucket: 'meditatorsgroup-dev.appspot.com',
    messagingSenderId: '736058416410',
    appId: '1:736058416410:web:e03bd3886da4fdd8fa60a3',
    measurementId: 'G-7PSMD9T41V',
  },
  analyticsTag: 'G-1WSDSLLPG9',
  url: 'dev-v64eit.meditators.group',
};
